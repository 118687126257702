<template>
  <FilterOverview title="Aufgabenübersicht" :showFilterInitially="false" @reload="onReload">
    <template #toolbar-left>
      <b-button-group size="sm" class="ml-2">
        <b-dropdown
          size="sm"
          :variant="isMeineAnsicht ? 'primary' : ''"
          :text="isMeineAnsicht ? ansicht : 'Meine'"
        >
          <b-dropdown-item @click="changeFilterButton" id="Mir zugewiesen">Mir zugewiesen</b-dropdown-item>
          <b-dropdown-item @click="changeFilterButton" id="Von mir erstellt">
            Von mir erstellt
          </b-dropdown-item>
          <b-dropdown-item @click="changeFilterButton" id="Von mir beobachtet">
            Von mir beobachtet
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          size="sm"
          :variant="isTeamAnsicht ? 'primary' : ''"
          :text="isTeamAnsicht ? ansicht : 'Team'"
        >
          <b-dropdown-item @click="changeFilterButton" id="Vom Team erstellt">
            Vom Team erstellt
          </b-dropdown-item>
          <b-dropdown-item @click="changeFilterButton" id="Team zugewiesen">Team zugewiesen</b-dropdown-item>
        </b-dropdown>
        <b-button
          size="sm"
          @click="changeFilterButton"
          id="Nicht zugewiesen"
          :variant="ansicht === 'Nicht zugewiesen' ? 'primary' : ''"
        >
          Nicht zugewiesen
          <b-badge
            v-if="teamNotificationCount > 0"
            variant="danger"
            style="
              position: absolute;
              top: -10px;
              right: -12px;
              border-radius: 12px;
              padding: 4px;
              min-width: 24px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            {{ teamNotificationCount }}
          </b-badge>
        </b-button>
      </b-button-group>

      <b-form-input
        v-model="fullTextSearchInput"
        placeholder="Suchen..."
        size="sm"
        class="ml-4"
        @keyup.enter="handleEnterOnFullTextSearch"
        style="width: 150px"
      ></b-form-input>
      <b-form-checkbox class="ml-7" switch v-model="pendingStatusCheckbox" @change="pendingStatusChanged">
        Mit Pending
      </b-form-checkbox>
      <div
        class="ml-3 d-flex"
        v-if="isAdmin && showFakeUserSelect"
        style="
          background-color: #3699ff;
          padding: 4px;
          border-radius: 10px;
          color: white;
          white-space: nowrap;
          align-items: center;
        "
      >
        <span class="mr-2">Fake User:</span>
        <UsersMultiselect
          v-model="fakeUser"
          :options="zugewiesenePersonOptions"
          class="mr-2 border"
          style="font-size: 14px; height: 32px; flex-grow: 1; border-radius: 8px"
        ></UsersMultiselect>
        <i
          v-if="fakeUser !== null"
          class="fas fa-times text-danger align-self-center ml-2"
          @click="fakeUser = null"
          style="cursor: pointer"
        ></i>
      </div>
      <b-button size="sm" class="ml-4" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
        Alle Filter zurücksetzen
      </b-button>
    </template>
    <template #toolbar-right>
      <b-button
        variant="secondary"
        size="sm"
        class="mr-3"
        v-b-modal.bulkZuweisenModal
        v-if="allSelectedRows.length > 1"
      >
        Neu zuweisen
      </b-button>
      <BulkZuweisenModal
        :selectedAufgabenCount="allSelectedRows.length"
        :selectedAufgabenIds="selectedAufgabenIds"
        @neuZugewiesen="neuZugewiesen"
      ></BulkZuweisenModal>
      <b-button variant="primary" size="sm" v-b-modal.modalAufgabeEdit v-if="allSelectedRows.length == 0">
        Aufgabe erstellen
      </b-button>
      <AufgabeErstellenModal @aufgabeErstellt="gridApi.onFilterChanged()"></AufgabeErstellenModal>
    </template>

    <template #table="{ tableHeight }">
      <AufgabeDetailsModal
        @aufgabeBearbeitet="onAufgabeBearbeitet"
        :currentUserId="currentUserId"
        @rowSelectionChanged="changeRowSelection"
        @updateOverviewData="updateOverviewData"
      ></AufgabeDetailsModal>
      <AgGridVue
        :columnDefs="columnDefs"
        :rowModelType="rowModelType"
        :tooltipShowDelay="tooltipShowDelay"
        :tooltipHideDelay="tooltipHideDelay"
        :statusBar="statusBar"
        :defaultColDef="defaultColDef"
        :style="{ height: tableHeight + 80 + 'px' }"
        :rowSelection="'multiple'"
        :rowMultiSelectWithClick="true"
        @grid-ready="onGridReady"
        @rowDoubleClicked="onRowClick"
        @rowSelected="onRowSelected"
        class="ag-theme-alpine m-0 p-0"
        id="agGridAufgaben"
      ></AgGridVue>
    </template>
  </FilterOverview>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import FilterOverview from '@/components/common/filter-overview.vue';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import UsersMultiselect from '@/components/common/users-multiselect.vue';

import {
  GET_AUFGABEN,
  SET_FILTER_ANSICHT,
  SET_AUFGABE_DETAIL_DATA,
  GET_FILTER_OPTIONS,
  GET_FILTER_OPTIONS_ATTACHMENTS,
} from '@/core/aufgaben/stores/aufgaben.module';
import { mapState, mapGetters } from 'vuex';
import { format, differenceInCalendarDays } from 'date-fns';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';
import AufgabeDetailsModal from '@/components/aufgaben/aufgabe-details-modal.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import NameTooltip from '@/components/aufgaben/name-tooltip.vue';
import BeschreibungTooltip from '@/components/aufgaben/beschreibung-tooltip.vue';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { kategorienSortRanking } from '@/core/common/helpers/utils.js';
import BulkZuweisenModal from '@/components/aufgaben/bulk-zuweisen-modal.vue';

export default {
  components: {
    FilterOverview,
    AgGridVue,
    StatusBarComponent,
    BulkZuweisenModal,
    AufgabeErstellenModal,
    AgDateRangePicker,
    NameTooltip,
    agColumnHeader: CustomHeader,
    AufgabeDetailsModal,
    BeschreibungTooltip,
    UsersMultiselect,
  },
  watch: {
    fakeUser() {
      this.gridApi.onFilterChanged();
    },
  },
  data() {
    return {
      showFakeUserSelect: false,
      fakeUser: null,
      attachmentFilterData: {},
      subnavFilterButtons: ['Mir zugewiesen', 'Von mir erstellt', 'Von mir beobachtet', 'Vom Team erstellt'],
      activeFilterButton: 'Mir zugewiesen',
      columnDefs: null,
      fullTextSearchInput: null,
      tooltipShowDelay: null,
      tooltipHideDelay: null,
      statusBar: null,
      allSelectedRows: [],
      pendingStatusCheckbox: false,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      rowData: null,
      rowModelType: null,
      lastRequest: null,
      isFilterSupplied: false,
      selectedRowID: null,
      whichTabWasLoaded: null,
    };
  },
  created() {
    this.rowModelType = 'serverSide';
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.columnDefs = [
      {
        field: 'id',
        headerName: 'ID',
        width: 110,
        minWidth: 110,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: 'Verlinkungen',
        field: 'attachmentAufgabe',
        width: 250,
        minWidth: 250,
        sortable: false,
        tooltipValueGetter: params => {
          const allReisekuerzel = this.getAllVerlinkungenAufgabe(params.data);
          return allReisekuerzel.length > 0 ? [...new Set(allReisekuerzel)].join(', ') : null;
        },
        cellRenderer: params => {
          const allReisekuerzel = this.getAllVerlinkungenAufgabe(params.data);
          return allReisekuerzel.length > 0
            ? allReisekuerzel.length > 3
              ? [...new Set(allReisekuerzel)].slice(0, 3).join(', ') + '...'
              : [...new Set(allReisekuerzel)].slice(0, 3).join(', ')
            : null;
        },
        filterParams: {
          debounceMs: 1000,
          values: async params => {
            const query = { ...this.lastRequest };
            const values = await this.$store.dispatch(GET_FILTER_OPTIONS_ATTACHMENTS, query);
            const formatValues = Object.values(values).reduce((acc, curr) => {
              if (curr.reiseTermine.length > 0) {
                curr.reiseTermine.forEach(item => {
                  acc[item.reiseterminkuerzel] = item.id;
                });
              }
              if (curr.reisen.length > 0) {
                curr.reisen.forEach(item => {
                  acc[item.reisekuerzel] = item.id;
                });
              }
              if (curr.vorgaenge.length > 0) {
                curr.vorgaenge.forEach(item => {
                  acc[item.id] = item.id;
                });
              }
              if (curr.flugverfuegbarkeiten.length > 0) {
                curr.flugverfuegbarkeiten.forEach(item => {
                  acc[item.reisetermin.reiseterminkuerzel + '_' + item.deutscherAbflughafenIataCode] =
                    item.id;
                });
              }

              return acc;
            }, {});
            this.attachmentFilterData = formatValues;
            this.whichTabWasLoaded = this.activeFilterButton;
            params.success(Object.keys(formatValues));
          },
          comparator: (a, b) => {
            if (!isNaN(parseInt(a)) && !isNaN(parseInt(b))) return 0;
            if (!isNaN(parseInt(a))) {
              return 1;
            }
            if (!isNaN(parseInt(b))) {
              return -1;
            } else return a.localeCompare(b);
          },
        },
        filter: 'agSetColumnFilter',
        floatingFilterComponent: true,
      },
      {
        field: 'kategorie',
        headerName: 'Kategorie',
        width: 250,
        minWidth: 250,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: params =>
            this.getAllKategorien?.find(item => item.aufgabenKategorie === params.value)?.labelFrontend,
          debounceMs: 1000,
          values: async params => {
            const query = { ...this.lastRequest, attribute: 'kategorie' };
            const kategorie = await this.$store.dispatch(GET_FILTER_OPTIONS, query);
            const uniqueKategorien = [...new Set(kategorie)];
            this.whichTabWasLoaded = this.activeFilterButton;

            params.success(uniqueKategorien);
          },
          comparator: (a, b) => {
            const rankingA = kategorienSortRanking.hasOwnProperty(a.toString())
              ? kategorienSortRanking[a]
              : 5;
            const rankingB = kategorienSortRanking.hasOwnProperty(b.toString())
              ? kategorienSortRanking[b]
              : 5;
            if (rankingA === rankingB) {
              return a.localeCompare(b);
            } else return rankingA > rankingB ? 1 : -1;
          },
        },
        valueFormatter: params => {
          return this.getAllKategorien?.find(item => item.aufgabenKategorie === params.value)?.labelFrontend;
        },
        floatingFilter: true,
      },
      {
        field: 'status',
        width: 140,
        minWidth: 140,
        headerName: 'Status',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Offen', 'Erledigt', 'InArbeit', 'Pending'],
          debounceMs: 1000,
          valueFormatter: params => (params.value === 'InArbeit' ? 'In Arbeit' : params.value),
          cellRenderer: params =>
            params.value === 'Pending'
              ? '<span style="width:24px; padding: 4px; background-color:#888" class=" badge badge-secondary"><i class="fas fa-sm fa-clock" style="color:#fff"></i></span><span class="ml-2">Pending</span>'
              : params.value === 'Erledigt'
              ? '<span style="width:24px; padding: 4px" class=" badge badge-success"><i class="fas fa-sm fa-check" style="color:#fff"></i></span><span class="ml-2">Erledigt</span>'
              : params.value === 'InArbeit'
              ? '<span style="width:24px; padding: 4px" class=" badge badge-warning"> <i class="far fa-sm fa-clock" style="color: #fff;"></i></span><span class="ml-2">In Arbeit</span>'
              : params.value === 'Offen'
              ? '<span style="width:24px; padding: 4px" class=" badge badge-danger"> <i class="fas fa-sm fa-exclamation" style="color: #fff;"></i></span><span class="ml-2">Offen</span>'
              : params.value,
        },
        cellRenderer: params =>
          params.value === 'Pending'
            ? '<span style="width:24px; padding: 4px; background-color:#888" class=" badge badge-secondary"><i class="fas fa-clock" style="color:#fff"></i></span><span class="ml-2">Pending</span>'
            : params.value === 'Erledigt'
            ? '<span style="width: 24px; padding: 4px" class="badge badge-success"><i class="fas fa-check" style="color:#fff"></i></span><span class="ml-2">Erledigt</span>'
            : params.value === 'InArbeit'
            ? '<span style="width: 24px; padding: 4px" class="badge badge-warning"> <i class="far fa-clock" style="color: #fff;"></i></span><span class="ml-2">In Arbeit</span>'
            : params.value === 'Offen'
            ? '<span style="width: 24px; padding: 4px" class=" badge badge-danger"> <i class="fas fa-exclamation" style="color: #fff;"></i></span><span class="ml-2">Offen</span>'
            : params.value,
      },
      {
        field: 'beschreibung',
        headerName: 'Beschreibung',
        tooltipField: 'beschreibung',
        width: 160,
        minWidth: 160,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        tooltipComponent: 'BeschreibungTooltip',

        cellRenderer: params =>
          params.value?.startsWith('<') ? `<span v-html=${params.value}></span>` : params.value,
      },

      {
        field: 'bearbeiter',
        headerName: 'Bearbeiter',
        filter: 'agSetColumnFilter',
        width: 180,
        minWidth: 180,
        tooltipComponent: 'NameTooltip',
        tooltipValueGetter: params => ({ value: this.$store.getters.getUsernameById(params.value) }),
        filterParams: {
          keyCreator: params => params.value.value,
          valueFormatter: params => params.value.text,
          debounceMs: 1000,
          comparator: (a, b) => {
            const nameA = a.text;
            const nameB = b.text;
            if (nameA === nameB) return 0;
            return nameA > nameB ? 1 : -1;
          },
          values: async params => {
            const query = { ...this.lastRequest, attribute: 'bearbeiter' };
            const bearbeiter = await this.$store.dispatch(GET_FILTER_OPTIONS, query);
            const uniqueBearbeiter = [...new Set(bearbeiter)];
            this.whichTabWasLoaded = this.activeFilterButton;

            params.success(
              this.zugewiesenePersonOptions.filter(user => uniqueBearbeiter.includes(user.value))
            );
          },
        },
        floatingFilter: true,
        cellRenderer: params => {
          if (params.data.team) return params.data.team;
          const name = this.$store.getters.getUsernameById(params.value);
          const shortenedName = name
            ?.split(' ')
            .map((subName, i) => {
              if (i == 0) return subName;
              else return subName.substring(0, 1) + '.';
            })
            .join(' ');

          return shortenedName;
        },
      },
      {
        field: 'faelligkeitsdatum',
        width: 180,
        minWidth: 180,
        headerName: 'Fälligkeitsdatum',
        filter: 'AgDateRangePicker',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        cellRenderer: params => {
          const faelligkeitDate = format(new Date(params.value), 'dd.MM.');
          const tageBisFaelligkeit = differenceInCalendarDays(new Date(params.value), new Date());

          if (tageBisFaelligkeit <= 0) {
            return `<span style="width: 85px" class='badge badge-danger'>${faelligkeitDate} (${tageBisFaelligkeit})</span>`;
          } else
            return `<span style="width: 85px" class='badge badge-secondary'>${faelligkeitDate} (${tageBisFaelligkeit})</span>`;
        },
      },
      {
        field: 'prioritaet',
        headerName: 'Priorität',
        filter: 'agSetColumnFilter',
        sortable: true,
        width: 120,
        minWidth: 120,
        filterParams: {
          values: [2, 1, 0],
          debounceMs: 1000,
          valueFormatter: params => (params.value === 0 ? 'Hoch' : params.value === 1 ? 'Mittel' : 'Niedrig'),
          cellRenderer: params =>
            params.value === 2
              ? '<span style="width: 24px; padding: 4px" class=" badge badge-danger"><i class="fas fa-sm fa-arrow-up" style="color: #fff;"></i> </span><span class="ml-2">Hoch</span>'
              : params.value === 1
              ? '<span style="width: 24px; padding: 4px" class=" badge badge-warning"><i class="fas fa-sm fa-arrow-right" style="color: #fff;"></i></span><span class="ml-2">Mittel</span>'
              : params.value === 0
              ? '<span style="width: 24px; padding: 4px" class=" badge badge-success"><i class="fas fa-sm fa-arrow-down" style="color: #fff;"></i></span><span class="ml-2">Niedrig</span>'
              : params.value,
        },

        cellRenderer: params =>
          params.value === 2
            ? '<span style="width: 24px; padding: 4px" class=" badge badge-danger"><i class="fas fa-arrow-up" style="color: #fff;"></i></i></span><span class="ml-2">Hoch</span>'
            : params.value === 1
            ? '<span style="width: 24px; padding: 4px" class=" badge badge-warning"><i class="fas fa-arrow-right" style="color: #fff;"></i></span><span class="ml-2">Mittel</span>'
            : params.value === 0
            ? '<span style="width: 24px; padding: 4px" class=" badge badge-success"><i class="fas fa-arrow-down" style="color: #fff;"></i></span><span class="ml-2">Niedrig</span>'
            : params.value,
        floatingFilter: true,
      },
      {
        field: 'autor',
        headerName: 'Autor',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        width: 120,
        minWidth: 120,
        tooltipComponent: 'NameTooltip',
        tooltipValueGetter: params => ({
          value: this.$store.getters.getUsernameById(params.value) || 'Automatisch erstellt',
        }),
        filterParams: {
          values: params => {
            setTimeout(() => {
              params.success(this.zugewiesenePersonOptions);
            }, 100);
          },
          keyCreator: params => params.value.value,
          valueFormatter: params => params.value.text,
          debounceMs: 1000,
          comparator: (a, b) => {
            const nameA = a.text;
            const nameB = b.text;
            if (nameA === nameB) return 0;
            return nameA > nameB ? 1 : -1;
          },
        },
        cellRenderer: params => {
          if (!params.value) return 'Automatisch erstellt';
          const firstName = this.$store.getters.getUsernameById(params.value)?.split(' ')[0];
          const lastName = this.$store.getters.getUsernameById(params.value)?.split(' ')[1];
          if (!firstName || !lastName) return '';
          return `${firstName} ${lastName.substring(0, 1) + '.'}`;
        },
      },
      {
        field: 'createdDateUtc',
        headerName: 'Erstellungsdatum',
        width: 200,
        minWidth: 200,
        filter: 'AgDateRangePicker',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        cellRenderer: params => format(new Date(params.value), 'dd.MM.yyyy HH:mm'),
      },

      {
        field: 'fullTextSearch',
        filter: 'agTextColumnFilter',
        hide: true,
      },
    ];
    this.tooltipShowDelay = 0;
    this.tooltipHideDelay = 2000;
  },
  mounted() {
    this.addKeyEventListener();
  },
  onUnmounted() {
    this.removeKeyEventListener();
  },
  computed: {
    ...mapState({
      users: state => state.users['users'],
      aufgabenData: state => state.aufgaben.aufgaben,
      ansicht: state => state.aufgaben.filters.ansicht,
      count: state => state.aufgaben.count,
      teamNotificationCount: state => state.aufgaben.teamNotificationCount,
    }),
    ...mapGetters(['currentUserId', 'getAllKategorien', 'parsedRoles']),
    isAdmin() {
      return this.parsedRoles.includes('Admin');
    },
    isTeamAnsicht() {
      return this.ansicht === 'Vom Team erstellt' || this.ansicht === 'Team zugewiesen';
    },
    isMeineAnsicht() {
      return (
        this.ansicht === 'Mir zugewiesen' ||
        this.ansicht === 'Von mir erstellt' ||
        this.ansicht === 'Von mir beobachtet'
      );
    },
    zugewiesenePersonOptions() {
      return this.users
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ givenName, surname, id }) => ({
          value: id,
          text: givenName + ' ' + surname,
        }));
    },
    zugewiesenePersonOnlyNames() {
      return this.zugewiesenePersonOptions.map(user => user.text);
    },
    selectedAufgabenIds() {
      if (this.allSelectedRows.length > 0) {
        return this.allSelectedRows.map(rowNode => rowNode.data.id);
      }
      return [];
    },
  },
  methods: {
    addKeyEventListener() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    removeKeyEventListener() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    handleKeyPress($event) {
      if ($event.key === 'x') {
        this.showFakeUserSelect = !this.showFakeUserSelect;
      }
    },
    onReload() {
      this.gridApi.onFilterChanged();
    },
    pendingStatusChanged() {
      this.gridApi.onFilterChanged();
    },
    neuZugewiesen() {
      this.gridApi.deselectAll();
      this.gridApi.onFilterChanged();
    },
    onRowSelected($event) {
      this.allSelectedRows = this.getAllSelectedRows();
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setMarkedCount(this.allSelectedRows.length);
    },
    getAllSelectedRows() {
      const rowsSelected = [];
      this.gridApi.forEachNode((node, index) => {
        if (node.selected) {
          rowsSelected.push(node);
        }
      });
      return rowsSelected;
    },
    updateOverviewData(updatedAufgabe) {
      this.gridApi.forEachNode(node => {
        if (updatedAufgabe.id === node.data.id) {
          node.updateData(updatedAufgabe);
        }
      });
      if (this.ansicht === 'Von mir beobachtet') {
        this.gridApi.onFilterChanged();
      }
    },
    checkIfFilterSupplied() {
      this.isFilterSupplied =
        this.gridApi &&
        (Object.keys(this.gridApi?.getFilterModel()).length > 0 || this.pendingStatusCheckbox);
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      let filterDate1 = this.gridApi.getFilterInstance('faelligkeitsdatum');
      let filterDate2 = this.gridApi.getFilterInstance('createdDateUtc');
      this.fullTextSearchInput = null;
      this.pendingStatusCheckbox = false;
      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      this.$router.replace(this.$route.query);

      this.gridApi.deselectAll();
    },
    changeFilterButton($event) {
      this.resetAllFilters();
      const { id } = $event.target;
      if (this.activeFilterButton !== id) {
        this.activeFilterButton = id;
        this.$store.commit(SET_FILTER_ANSICHT, id);
        this.gridApi.deselectAll();
        this.gridApi.onFilterChanged();
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      if (this.$route.query.aufgabenId) {
        const aufgabenIdFilter = this.gridApi?.getFilterInstance('id');
        if (!isNaN(this.$route.query.aufgabenId)) {
          aufgabenIdFilter.setModel({
            filterType: 'text',
            type: 'equal',
            filter: this.$route.query.aufgabenId,
          });
        }
        this.gridApi.onFilterChanged();
      }
      const defaultSortModel = [
        { colId: 'faelligkeitsdatum', sort: 'asc', sortIndex: 0 },
        { colId: 'prioritaet', sort: 'desc', sortIndex: 1 },
      ];

      params.columnApi.applyColumnState({ state: defaultSortModel });
      const updateData = () => {
        const fakeServer = this.createFakeServer();
        const datasource = this.createServerSideDatasource(fakeServer);
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
    },
    createServerSideDatasource(server) {
      return {
        getRows: async params => {
          console.log('[Datasource] - rows requested by grid: ', params.request);
          const response = await server.getData(params.request);

          if (response.success) {
            // supply rows for requested block to grid
            params.success({ rowData: response.rows.result.value });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }

          if (this.$route.query.aufgabenId && response.rows.result.value.length > 0) {
            let rowData;
            this.gridApi.deselectAll();
            this.gridApi.forEachNode(rowNode => {
              if (rowNode.rowIndex === 0) {
                rowNode.setSelected(true, true);
                rowData = rowNode.data;
              }
            });
            this.selectedRowID = 0;
            this.$store.commit(SET_AUFGABE_DETAIL_DATA, rowData);
            this.$root.$emit('bv::show::modal', 'modalAufgabeDetails');
          }
        },
      };
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    getAllVerlinkungenAufgabe(data) {
      const verlinkungenFlugverfuegbarkeiten =
        data.flugverfuegbarkeiten.length > 0
          ? data.flugverfuegbarkeiten.map(
              flugverfuegbarkeit =>
                flugverfuegbarkeit.reisetermin.reiseterminkuerzel +
                '_' +
                flugverfuegbarkeit.deutscherAbflughafenIataCode
            )
          : [];
      const verlinkungenReisetermine =
        data.reiseTermine.length > 0
          ? data.reiseTermine.map(reisetermin => reisetermin.reiseterminkuerzel)
          : [];
      const verlinkungenReise = data.reisen.length > 0 ? data.reisen.map(reise => reise.reisekuerzel) : [];
      const verlinkungenVorgaenge =
        data.vorgaenge.length > 0 ? data.vorgaenge.map(vorgang => vorgang.id) : [];
      return [
        ...verlinkungenFlugverfuegbarkeiten,
        ...verlinkungenReise,
        ...verlinkungenReisetermine,
        ...verlinkungenVorgaenge,
      ];
    },
    createFakeServer() {
      return {
        getData: async request => {
          this.checkIfFilterSupplied();
          const req = {
            ...request,
            pendingCheckbox: this.pendingStatusCheckbox,
            attachmentFilterData: this.attachmentFilterData,
            fakeUser: this.fakeUser,
          };
          this.lastRequest = req;
          const response = await this.$store.dispatch(GET_AUFGABEN, req);
          await this.refreshColumnSetFilterValues();
          return {
            success: true,
            rows: response,
          };
        },
      };
    },
    refreshColumnSetFilterValues() {
      if (this.whichTabWasLoaded !== this.activeFilterButton) {
        this.gridApi.getFilterInstance('kategorie')?.refreshFilterValues();
        this.gridApi.getFilterInstance('bearbeiter')?.refreshFilterValues();
        this.gridApi.getFilterInstance('attachmentAufgabe')?.refreshFilterValues();
      }
    },
    onRowClick($event) {
      const { data, rowIndex } = $event;
      this.gridApi.deselectAll();
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.rowIndex === rowIndex) {
          rowNode.setSelected(true, true);
        }
      });
      this.selectedRowID = rowIndex;
      this.$store.commit(SET_AUFGABE_DETAIL_DATA, data);
      this.$root.$emit('bv::show::modal', 'modalAufgabeDetails');
    },
    changeRowSelection(direction) {
      const displayedRows = this.gridApi?.getDisplayedRowCount();
      if (direction === 'up') {
        if (this.selectedRowID !== 0) {
          const targetedRowID = this.selectedRowID - 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.$store.commit(SET_AUFGABE_DETAIL_DATA, rowNode.data);
              this.selectedRowID = targetedRowID;
            }
          });
        } else if (this.selectedRowID === 0) {
          const targetedRowID = this.gridApi.getLastDisplayedRow();
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.$store.commit(SET_AUFGABE_DETAIL_DATA, rowNode.data);
              this.selectedRowID = targetedRowID;
            }
          });
        }
      } else if (direction === 'down') {
        if (this.selectedRowID + 1 < displayedRows) {
          const targetedRowID = this.selectedRowID + 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.$store.commit(SET_AUFGABE_DETAIL_DATA, rowNode.data);
              this.selectedRowID = targetedRowID;
            }
          });
        } else if (this.selectedRowID + 1 === displayedRows) {
          const targetedRowID = 0;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.$store.commit(SET_AUFGABE_DETAIL_DATA, rowNode.data);
              this.selectedRowID = targetedRowID;
            }
          });
        }
      }
    },
    handleEnterOnFullTextSearch($event) {
      // Add filter to ag grid filter model
      const { value } = $event.target;
      const fullTextSearchFilterInstance = this.gridApi.getFilterInstance('fullTextSearch');
      fullTextSearchFilterInstance.setModel({
        filter: value,
        type: 'contains',
      });
      this.gridApi.onFilterChanged();
    },
    onAufgabeBearbeitet(changedFields = null) {
      console.log(changedFields);
      if (changedFields !== null) {
        const { id } = changedFields;
        if (id !== null) {
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.data.id === id) {
              const updated = rowNode.data;
              updated.bearbeiter = changedFields.bearbeiter;
              updated.beschreibung = changedFields.beschreibung;
              updated.faelligkeitsdatum = changedFields.faelligkeitsdatum;
              updated.prioritaet = changedFields.prioritaet;
              updated.status = changedFields.status;
              rowNode.updateData(updated);
            }
          });
        }
      }
    },
  },
};
</script>
<style scoped>
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-tabs) {
  min-width: 270px;
}
</style>
